/* Navbar.css */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
}

.logo {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.logo img {
    width: 200px;
    height: auto;
}

.menu-icon {
    cursor: pointer;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
  }

.hamburger {
    width: 25px;
    height: 3px;
    background-color: #000000;
    margin: 30px 0;
    position: relative;
}

.hamburger::before,
.hamburger::after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: #000000;
    position: absolute;
    transition: all 0.3s ease;
    /* Add transition for smooth animation */
}

.hamburger::before {
    top: -8px;
}

.hamburger::after {
    bottom: -8px;
    /* Use bottom instead of top */
}

.open::before {
    transform: translateY(8px) rotate(45deg);
}

.open::after {
    transform: translateY(-8px) rotate(-45deg);
}
