
.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-content {
    background-color: #647457;
    width: 18em;
    padding: 20px;
    border-radius: 5px;
    display: block;
}

.menu-content h1{
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bold;
}

.menu-content hr{
    color: white;
    width: 70%;
}

.menu-content a{
    color: white;
    align-items: center;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
}

.menu-options {
    text-align: center;
    display: block;
}

.menu-options li {
    display: block;
    color: rgb(255, 255, 255);
    text-decoration: none;
    margin: 10px 0;
}

.menu-options a {
    display: block;
    color: rgb(255, 255, 255);
    text-decoration: none;
    margin: 10px 0;
    font-weight: lighter;
}

.menu-options a:hover {
    color: #000000;
}